import React from "react";
import { Link } from "react-router-dom";

function PayslipScreen({ url, payslip }) {
  return (
    <div>
      <h1>Hello I am payslip.</h1>
      <pre>{JSON.stringify(payslip)}</pre>
      <Link to={`${url}/assignment-recon`}>Go to Assignment Reconciliation</Link>
    </div>
  );
}

export default PayslipScreen;
