import React from "react";
import "./App.css";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { PayslipCacheProvider } from "./api";
import queryString from "query-string";
import PayslipContainer from "./containers/PayslipContainer";
import IllustrationContainer from "./containers/IllustrationContainer";

function App() {
  const { token } = queryString.parse(window.location.search);

  return (
    <PayslipCacheProvider token={token}>
      <BrowserRouter>
        <Switch>
          <Route path="/payslip/:id">
            <PayslipContainer />
          </Route>
          <Route path="/illustration/:id">
            <IllustrationContainer />
          </Route>
          <Route path="/">
            <div>Invalid URL</div>
          </Route>
        </Switch>
      </BrowserRouter>
    </PayslipCacheProvider>
  );
}

export default App;
