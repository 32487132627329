import React from "react";
import { useParams, useRouteMatch, Switch, Route, Redirect } from "react-router-dom";
import { usePayslip } from "../api";
import { PageLoading } from "@backslashbuild/pp-ui";
import AssignmentReconScreen from "../components/AssignmentReconScreen";
import PayslipScreen from "../components/PayslipScreen";

function PayslipContainer() {
  const { id } = useParams();
  const match = useRouteMatch();
  const payslip = usePayslip(id);

  if (payslip == null) {
    return <PageLoading />;
  }

  return (
    <Switch>
      <Route path={`${match.url}/payslip`}>
        <AssignmentReconScreen payslip={payslip} url={match.url} />
      </Route>
      <Route path={`${match.url}/assignment-recon`}>
        <PayslipScreen payslip={payslip} url={match.url} />
      </Route>
      <Redirect from="*" to={`${match.url}/assignment-recon`} />
    </Switch>
  );
}

export default PayslipContainer;
