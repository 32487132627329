// this handles talking to the backend api
// unlike the other portals, this portal does not use OIDC,
// instead it is given a one-time-use JWT (actually a 2-5 min expiry)

import React from "react";

const apiUrl = process.env.REACT_APP_API_HOST;

const PayslipCacheContext = React.createContext({});

export function PayslipCacheProvider({ token, children }) {
  const [payslips, setPayslips] = React.useState({});

  function read(id) {
    return payslips[id] ?? null;
  }

  function write(newPayslips) {
    setPayslips((cache) => {
      const newCache = { ...cache };

      for (let newPayslip of newPayslips) {
        // could check the circumstance that the cache already has a valid one and we've just loaded an error

        newCache[newPayslip.id] = newPayslip;
      }

      return newCache;
    });
  }

  return (
    <PayslipCacheContext.Provider value={{ read, write, token }}>
      {children}
    </PayslipCacheContext.Provider>
  );
}

export function useLoad(type = "payslips") {
  const payslipCache = React.useContext(PayslipCacheContext);

  return (ids) => {
    for (let id of ids) {
      console.log("fetching...", {
        url: `${apiUrl}/payroll/${type}/render/${id}`,
      });

      fetch(`${apiUrl}/payroll/${type}/render/${id}?token=${payslipCache.token}`)
        .then((r) => r.json())
        .then((res) => {
          console.log(res);
          if (res.isSuccess) {
            payslipCache.write([res.value]);
          }
        });
    }
  };
}

/**
 * loads the data for a payslip from the cache.
 * @param {string} id
 */
export function usePayslip(id, type = "payslips") {
  const load = useLoad(type);
  const payslipCache = React.useContext(PayslipCacheContext);
  const payslip = payslipCache.read(id);
  React.useEffect(() => {
    if (!payslip) {
      load([id]);
    }
  });
  return payslip;
}

/**
 * loads the data for a payslip from the cache.
 * @param {string} id
 */
export function useIllustration(id) {
  return usePayslip(id, "illustrations");
}
